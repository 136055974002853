import * as Icons from './icons-v2';
import {IconName} from './types';
import {ColorsType, useColors} from '../hooks';
import {View, ViewProps} from '../View';
import {createElement, memo} from 'react';

interface IconProps extends ViewProps {
  name: IconName;
  size?: string | number;
  height?: string | number;
  color?: ColorsType;
  accent?: ColorsType;
  opacity?: number;
  rtl?: boolean;
}
export const Icon = memo(function Icon({
  name,
  color = 'color',
  size = 24,
  height,
  accent = 'white',
  bg = 'transparent',
  rtl = false,
  style,
  extraProps = {},
  ...props
}: IconProps & {extraProps?: any}) {
  const colors = useColors();
  return (
    <View bg={bg} {...props} pointerEvents="none" style={[style, rtl && {transform: [{scaleX: -1}]}]}>
      {createElement(Icons[name], {
        color: colors[color],
        size,
        height,
        accent: colors[accent],
        ...extraProps,
      })}
    </View>
  );
});
